import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";

/**
 * 404 page not found
 *
 * Page for routes that don't yet exist or haven't been implemented yet.
 * Should keep layout consistent and notify the user.
 */
const NotFoundPage = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout title={siteTitle}>
      <title>{"404"}</title>

      <h1>Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
